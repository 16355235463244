import React from "react";
import ProjectModal from "../Modal/Modal";
import styles from "./Navbar.module.scss";

const Navbar = () => {
	const [modalIsOpen, setModalIsOpen] = React.useState(false);
	const openModal = () => {
		console.log("openModal");
		setModalIsOpen(true);
	};

	return (
		<div className={styles.navbarBtn}>
			{!modalIsOpen ?
			<div onClick={openModal} className={styles.navbarLines}>
						<div className={`${styles.line} ${styles.l0}`}/>
						<div className={`${styles.line} ${styles.l1}`}/>
						<div className={`${styles.line} ${styles.l2}`}/>
						<div className={`${styles.line} ${styles.l3}`}/>
			</div>
				:
				null
			}
			<ProjectModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}/>
		</div>
	)
}

export default Navbar;