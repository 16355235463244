form {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(24, 24, 24, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

label {
  color: white;
  font-family: NeuzeitSLTStd-Book, sans-serif;
  margin-bottom: 4px;
  text-decoration: underline;
}

input {
  height: 26px;
}

input,
textarea {
  font-family: 'NeuzeitSLTStd-Book', sans-serif;
  margin-bottom: 40px;
  width: 100%;
  border: none;
  background: rgb(85, 85, 85);
  background: linear-gradient(315deg, rgba(190, 190, 190, 1) 0%, rgba(214, 214, 214, 1) 100%);
  border-bottom: aquamarine 3px solid;
}

textarea {
  padding: 5px;
  height: 200px;
}

.submitBtn {
  background: rgb(85, 85, 85);
  background: linear-gradient(315deg, rgba(190, 190, 190, 1) 0%, rgba(214, 214, 214, 1) 100%);
  border: none;
  color: white;
  font-family: NeuzeitSLTStd-Book, sans-serif;
  font-weight: lighter;
  padding: 10px;
  width: 150px;
  cursor: pointer;
  transition: all .4s ease-in-out;
}

.submitBtn:hover {
  background: rgb(85, 85, 85);
  background: linear-gradient(315deg, rgba(214, 214, 214, 1) 0%, rgba(190, 190, 190, 1) 100%);
  color: black;
}

.success > h2,
.success > p {
  color: white;
  font-family: 'NeuzeitSLTStd-Book', sans-serif;
  text-align: center;
}