.navbarBtn {
    position: fixed;
    z-index: 9999;
    width: 40px;
    height: 50px;
    left: 34px;
    top: 30px;
    cursor: pointer;
}

.navbarLines {
    height: 100%;
    width: 100%;
}

.navbarLines:hover > .l0,
.navbarLines:hover > .l1,
.navbarLines:hover > .l2,
.navbarLines:hover > .l3 {
    height: 55px;
    transform: translateY(-7px);
}

.line {
    width: 3px;
    height: 40px;
    background-color: #2f2f2f;
    position: absolute;
}

.l0 {
    transition: all .1s ease-in;
}

.l1 {
    left: 12px;
    transition: all .2s ease-in;
}

.l2 {
    left: 24px;
    transition: all .3s ease-in;
}

.l3 {
    left: 36px;
    transition: all .4s ease-in;
}