@font-face {
  font-family: 'NeuzeitSLTStd-Bold';
  src: url('../../Fonts/NeuzeitSLTStd-BookHeavy.otf');
}

@font-face {
  font-family: 'NeuzeitSLTStd-Book';
  src: url('../../Fonts/NeuzeitSLTStd-Book.otf');
}

@font-face {
  font-family: 'Francisco-Serial-Light';
  src: url('../../Fonts/francisco-serial-light.ttf');
}

.heroContent {
  text-align: center;
  height: 100vh;
  width: 100%;
}

.heroText {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  height: 7rem; // height must be a bit less than the height of the text to hide the other texts
  margin: auto;
  overflow: hidden;

  ul {
    padding: 0;
    animation: scrollUp 10s infinite;
    margin: 0 0.675rem;

    li {
      display: flex;
      color: #2d2d2d;
      font-family: 'NeuzeitSLTStd-Bold', sans-serif;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 7rem;
      font-size: 5.5rem;
      list-style: none;
    }
  }
}

.heroText:nth-child(1) {
  top: 40%;
}

h1, h2 {
  color: #2d2d2d;
  font-family: 'NeuzeitSLTStd-Bold', sans-serif;
  margin: 0;
}

h3 {
  color: #3a3a3a;
  font-family: Francisco-Serial-Light, serif;
  margin-top: 25px;
}

h4 {
  font-family: 'NeuzeitSLTStd-Book', sans-serif;
  font-size: 14px;
  margin: 3px;
}

.heroFooter {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 65%;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
}

// Variables
$item-count: 4;

@keyframes scrollUp {
  @for $i from 1 through ($item-count - 1) {
    #{($i * 25) - 10%}, #{$i * 25%} {
      transform: translateY(calc(-100% / $item-count) * $i);
    }
  }
}

@keyframes translateArrow {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(10px);
  }
}

.arrowDown {
  margin-top: 10px;
  height: 30px;
}

.goDown {
  cursor: pointer;
  transition: all .1s ease-in-out;
  width: 60px;
}

.goDown:hover {
  transform: scale(1.1);
}

.goDown:hover > .arrowDown {
  animation-duration: .5s;
  animation-name: translateArrow;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@media screen and (max-width: 1250px) {
  .heroText {
    height: fit-content;
  }

  // Stop animation
  .heroText ul {
    animation: none;
  }

  // Hide first, third and fourth li
  .heroText ul li:nth-child(1) {
    display: none;
  }
  .heroText ul li:nth-child(3) {
    display: none;
  }
  .heroText ul li:nth-child(4) {
    display: none;
  }

  .heroText ul li {
    font-size: 4rem;
  }

  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 12px;
  }

  .heroFooter {
    top: 80%;
  }
}