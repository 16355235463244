.FeaturedWork {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 100px 0 0;
  z-index: 1;
}

.fwtitle {
  text-align: center;
  margin-bottom: 150px;
  color: #9a9a9a;
  transition: all .2s ease-in-out;
  z-index: 1;
}

.fwtitle:hover {
  color: #2d2d2d;
  transform: scale(1.025);
}

.FeaturedWorkItem {
  width: 100%;
  margin-bottom: 5vh;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.fwImg {
  width: 40vw;
  height: 40vh;
  object-fit: cover;
  filter: grayscale(1) contrast(0.3) brightness(1.75);
  transition: all .2s ease-in-out;
}

.fwImg:hover {
  filter: grayscale(0);
  transform: scale(1.02);
}

@media screen and (max-width: 1100px) {
  .fwtitle {
    font-size: 2.5rem;
    margin-bottom: 50px;
  }
  .fwImg {
    width: 90%;
    height: auto;
  }
}