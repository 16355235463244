import React from "react";
import Modal from "react-modal";
import ContactForm from "../ContactForm/ContactForm";
import styles from "./Modal.module.scss";
import {CSSTransition} from 'react-transition-group';
import './transitions.css';
import Close from "./close.svg";

// noinspection JSUnresolvedFunction
Modal.setAppElement("#app");

const ProjectModal = ({modalIsOpen, setModalIsOpen}) => {
	const closeModal = (e) => {
		e.stopPropagation();
		setModalIsOpen(false);
	};

	return (
		<CSSTransition
			in={modalIsOpen}
			timeout={300}
			classNames="dialog"
		>
			<Modal
				closeTimeoutMS={500}
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Example Modal"
				className={styles.Modal}
				overlayClassName={styles.Overlay}
			>
				<ContactForm/>
				<img className={styles.closeBtn} src={Close} alt="close" onClick={closeModal}/>
			</Modal>
		</CSSTransition>
	);
}

export default ProjectModal;