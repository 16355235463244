import React from 'react';
import styles from "./FeaturedWork.module.scss";
import Alteira from "./Projects/Alteira/Alteira.png";
import DemonSword from "./Projects/Alteira/DemonSword.png";
import DivineSword from "./Projects/Alteira/DivineSword.png";
import DragonShield from "./Projects/Alteira/DragonShield.png";
import EngineerShield from "./Projects/Alteira/EngineerShield.png";
import FireSword from "./Projects/Alteira/FireSword.png";
import FloralShield from "./Projects/Alteira/FloralShield.png";
import IceShield from "./Projects/Alteira/IceShield.png";
import IceSword from "./Projects/Alteira/IceSword.png";
import DragonicaIsoris from "./Projects/Dragonica/IsorisHeader.png";
import ThreeDxp from "./Projects/3dxp/3dxp.png";
import StreetTrial from "./Projects/StreetTrial/StreetTrial.png";
import Tricycle from "./Projects/3dtricycle/tricycle.png";

const FeaturedWork = () => {
	const FeaturedWorks = [
		["Lac X Films", "https://lacxfilms.tcaron.fr", "https://user-images.githubusercontent.com/19364687/159715568-327dbaeb-88b1-40a9-ae6f-7387634d6beb.png"],
		["3dxp", "https://3dxp.tcaron.fr", ThreeDxp],
		["3dtricycle", "https://3d.tcaron.fr", Tricycle],
		["Street Trial", "https://instagram.com/tho_macaron", StreetTrial],
		["Dragonica Isoris", "https://playdragonica.eu/", DragonicaIsoris],
		["Dragonica", "https://playdragonica.eu/", "https://game-guide.fr/wp-content/uploads/2015/11/Dragonia-titre.jpg"],
		["Alteira", "https://minecraft.fr/forum/threads/d%C3%A9veloppeur-le-vrai-mmorpg-mc-alteiria.202966/", Alteira],
	];

	return (
		<div className={styles.FeaturedWork}>
			<h1 className={styles.fwtitle}>Featured Works</h1>
			<div>
				{FeaturedWorks.map((work, index) => {
					return (
						<div className={styles.FeaturedWorkItem} key={index}>
							<a href={work[1]} target="_blank" rel="noopener noreferrer">
								<img className={styles.fwImg} src={work[2]} alt={work[0]}/>
							</a>
						</div>
					)
				})}
			</div>
		</div>
	);
};

export default FeaturedWork;