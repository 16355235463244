import React from 'react';
import {useForm, ValidationError} from '@formspree/react';
import styles from './Forms.module.scss';

const ContactForm = () => {
	const [state, handleSubmit] = useForm("mwkyydzj");
	if (state.succeeded) {
		return (
			<div className={styles.success}>
				<h2>Thank you!</h2>
				<p>Your message has been sent.</p>
			</div>
		);
	}

	return (
		<form onSubmit={handleSubmit}>
			<label htmlFor="name">
				Who are you?
			</label>
			<input
				id="name"
				type="name"
				name="name"
			/>
			<ValidationError
				prefix="Name"
				field="Name"
				errors={state.errors}
			/>
			<label htmlFor="email">
				What's your email?
			</label>
			<input
				id="email"
				type="email"
				name="email"
			/>
			<ValidationError
				prefix="Email"
				field="email"
				errors={state.errors}
			/>
			<label htmlFor="message">
				Message
			</label>
			<textarea
				id="message"
				name="message"
			/>
			<ValidationError
				prefix="Message"
				field="message"
				errors={state.errors}
			/>
			<button className={styles.submitBtn} type="submit" disabled={state.submitting}>
				Submit
			</button>
		</form>
	);
}

export default ContactForm;