import React from 'react';
import styles from "./Hero.module.scss";
import {Parallax} from "react-parallax";
import Earth from "url:./earth.png";
import ArrowDown from "url:./arrow-down.svg";

const Hero = () => {
	const windowWidth = window.innerWidth;
	return (
		<div>
			{windowWidth > 1100 ?
				<Parallax bgImage={Earth} strength={250}>
					<div className={styles.heroContent}>
						<div className={styles.heroText}>
							<ul>
								<li>un desarrollador fullstack</li>
								<li>a fullstack developer</li>
								<li>un développeur fullstack</li>
								<li>ein Fullstack-Entwickler</li>
							</ul>
						</div>
						<div className={styles.heroText}>
							<ul>
								<li>Hola, soy Thomas</li>
								<li>Hi, I'm Thomas</li>
								<li>Bonjour, je suis Thomas</li>
								<li>Hallo, ich bin Thomas</li>
							</ul>
						</div>
						<div className={styles.heroFooter}>
							<div className={styles.goDown}>
								<h4>WORKS</h4>
								<h4><i>Portfolio</i></h4>
								<img className={styles.arrowDown} src={ArrowDown} alt="arrow down"/>
							</div>
						</div>
					</div>
				</Parallax>
				:
				<div className={styles.heroContent}>
					<div className={styles.heroText}>
						<h1>Hi, I'm Thomas</h1>
						<h1>a fullstack developer</h1>
						<h3>France - <i>Paris</i></h3>
					</div>
					<div className={styles.heroFooter}>
						<h4>WORKS</h4>
						<h4><i>Portfolio</i></h4>
						<img className={styles.arrowDown} src={ArrowDown} alt="arrow down"/>
					</div>
				</div>
			}
		</div>
	);
};

export default Hero;