import React from "react";
import styles from "./Footer.module.scss";
import Instagram from "./Assets/instagram.svg";
import LinkedIn from "./Assets/linkedin.svg";
import Github from "./Assets/github.svg";
import Stackoverflow from "./Assets/stackoverflow.svg";
import Signature from "./Assets/signature.png";

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.signature}>
                <img src={Signature} alt="signature" />
            </div>
            <div className={styles.centralFooter}>
                <h4>Info - 3D - Sport</h4>
            </div>
            <div className={styles.socialIcons}>
                <img onClick={() => window.open("https://instagram.com/tho_macaron")} src={Instagram} alt="instagram" />
                <img onClick={() => window.open("https://www.linkedin.com/in/dev-thomas-caron/")} src={LinkedIn} alt="linkedin" />
                <img onClick={() => window.open("https://github.com/thomas-rooty")} src={Github} alt="github" />
                <img onClick={() => window.open("https://stackoverflow.com/users/15032117/rootkitty")} src={Stackoverflow} alt="stackoverflow" />
            </div>
        </div>
    )
};

export default Footer;