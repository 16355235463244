import React from "react";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Hero from "./Components/Hero/Hero";
import FeaturedWork from "./Components/FeaturedWork/FeaturedWork";
import Footer from "./Components/Footer/Footer";

const App = () => {
    return (
        <div className="App">
            <div className="fourLines">
                <div className="verticalLine"/>
                <div className="verticalLine"/>
                <div className="verticalLine"/>
                <div className="verticalLine"/>
            </div>
            <div className="header">
                <Navbar/>
            </div>
            <div className="page-container">
                <Hero/>
                <FeaturedWork/>
                <Footer/>
            </div>
        </div>
    )
};

export default App;