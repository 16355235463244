.Modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 75px;
  padding-left: 0;
  border: none;
}

.Overlay {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(12, 12, 12, 0.95);
  border: none;
}

.closeBtn {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  border: none;
  background: none;
  width: 30px;
  height: 30px;
  filter: invert(100%);
  transition: all .1s ease-in-out;
}

.closeBtn:hover {
  transform: scale(1.2);
}

/* Modal content */

.Modal > h2 {
  font-family: NeuzeitSLTStd-Book, sans-serif;
  font-weight: lighter;
  color: white;
}

@media screen and (max-width: 1100px) {
  form {
    width: 80%;
  }
}