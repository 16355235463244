.footer {
    display: flex;
    flex-direction: row;
    background-color: #2d2d2d;
    position: absolute;
    width: 100%;
    text-align: center;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
}

.signature {
    display: flex;
    margin-left: 50px;
    flex-direction: row;
    width: fit-content;
    align-items: center;
}

.signature > img {
    width: 150px;
    height: auto;
}

.centralFooter {
    display: flex;
    width: fit-content;
    align-items: center;
    line-height: 30px;
}

.centralFooter > h4 {
    margin: 0;
    color: #afafaf;
    letter-spacing: 1px;
}

.centralFooter > h4 > i {
    color: #5b5b5b;
}

.socialIcons {
    display: flex;
    margin-right: 50px;
    flex-direction: row;
    width: fit-content;
    align-items: center;
}

.socialIcons > img {
    filter: invert(100%);
    width: 30px;
    height: 30px;
    margin-left: 5px;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.socialIcons > img:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 1100px) {
    .centralFooter > h4 {
        font-size: 10px;
    }

    .socialIcons {
        margin-right: 2vw;
    }

    .signature {
        margin-left: 2vw;
    }

    .signature > img {
        width: 95px;
        height: auto;
    }
}